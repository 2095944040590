/*整个页面的容器*/
.container {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-screen {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-buttons {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.search-drag {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: red;
}

.image-single {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.image-double {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.double-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.double-left {
  /*background-color: red;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.double-right {
  /*background-color: green;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.double-panel img {
  height: 100%;
}

.title-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 10%;
  top: 0px;
}

.button {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.image-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-panel img {
  width: 100%;
}

.info-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.info-panel .info {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.info-panel .info .first {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
}

.info .radio-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.error-panel {
  display: -webkit-flex;
  display: flex;
  flex: 1;
  position: absolute;
  display:inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  color: red;
  font-size: 30px;
  font-weight: bold;
}

.no-image {
  display: -webkit-flex;
  display: flex;
  flex: 1;
  display:inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.errMsg {
  color: red;
  font-size: 30px;
  font-weight: bold;
}

.first .text {
  width: 90%;
  padding-left: 10px;
}

.first .check {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.info .second {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.load {
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgba(44,62,80,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.info-key {
  font-weight: bold;
}

.info-value {
  color: red;
}

.image-single .direction-icon {
  position: absolute;
}

.image-double .direction-icon {
  position: absolute;
}

.image-single .icon-left {
  left: -20px;
}

.image-single .icon-right {
  right: -20px;
}

.image-double .icon-left {
  left: 50%;
}

.image-double .icon-right {
  right: 0px;
}

.err-panel {
  display: -webkit-flex;
  display: flex;
  display:inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
